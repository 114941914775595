
@font-face {
    font-family: 'ClashDisplay-Bold';
    src: url('./fonts/ClashDisplay-Bold.otf');
}
@font-face {
    font-family: 'ClashDisplay-Extralight';
    src: url('./fonts/ClashDisplay-Extralight.otf');
}
@font-face {
    font-family: 'ClashDisplay-Light';
    src: url('./fonts/ClashDisplay-Light.otf');
}
@font-face {
    font-family: 'ClashDisplay-Medium';
    src: url('./fonts/ClashDisplay-Medium.otf');
}
@font-face {
    font-family: 'ClashDisplay-Regular';
    src: url('./fonts/ClashDisplay-Regular.otf');
}
@font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url('./fonts/ClashDisplay-Semibold.otf');
}