// /*
// 全局样式变量定义
// */
// // 全局字体定义
$font-family : 'Helvetica Neue For Number,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif';
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}
.d-flex {
  display: flex;
}
.justfify-between {
  justify-content: space-between;
}
.mt-60 {
  margin-top: 60px;
}
.color-blue {
  color: #1559ed;
}
/*ios去掉黑色遮罩*/
* {
  // touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {

  // -webkit-touch-callout:none;/*系统默认菜单被禁用*/
  
  // -webkit-user-select:none;/*webkit浏览器*/
  
  // -khtml-user-select:none;/*早起浏览器*/
  
  // -moz-user-select:none;/*火狐浏览器*/
  
  // -ms-user-select:none;/*IE浏览器*/
  
  // user-select:none;/*用户是否能够选中文本*/
  
}
.h4, h4 {
    font-size: calc(1.26563rem + .1875vw);
}
.h5, h5 {
  font-size: 1.17188rem;
}
.h6, h6 {
  font-size: .9375rem;
}
ul {
  margin: 0;
  padding: 0
}
a {
  text-decoration: none;
  color: inherit
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
ul li,dl dt{list-style: none}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background:transparent;  
	outline:none;  
	border:none;
}
button, select {
  text-transform: none;
}
button {
  border-radius: 0;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  background: transparent;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  border-color: transparent; // 若是本身具有 border 请删除此属性
  box-shadow:none;
}

input,button,select,textarea {
  outline:none
}
textarea {
  resize:none
}
.h-100 {
  height: 100%!important;
}
.flex-column {
  -ms-flex-direction: column!important;
  flex-direction: column!important;
}
.d-flex {
  display: flex;
}
.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}
.ms-auto {
  margin-left: auto!important;
}
.mb-3 {
  margin-bottom: 1rem!important;
}
.mt-2 {
  margin-top: 0.5rem!important;
}
.text-center {
  text-align: center!important;
}
.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}
.my-auto {
  margin-top: auto!important;
  margin-bottom: auto!important;
}
.font-weight {
  font-weight: bold
}
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.small, small {
  font-size: .875em;
}
.mb-0 {
  margin-bottom: 0!important;
}
.mb-2 {
  margin-bottom: 0.5rem!important;
}
.rounded-circle {
  border-radius: 50%!important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6!important;
}
.me-1 {
  margin-right: 0.25rem!important;
}
.me-2 {
  margin-right: 0.5rem!important;
}
img, svg {
  vertical-align: middle;
}
.fade:not(.show) {
  opacity: 0;
}
.w-100 {
  width: 100%!important;
}
.text-left {
  text-align: left;
}
.fade {
  transition: opacity .15s linear;
}
.bg-light {
  background-color: rgba(246, 246, 246, 1)!important;
}
.p-4 {
  padding: 1.5rem!important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-light {
  color: #000;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  // box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.05469rem;
  border-radius: .375rem;
}
.btn-icon.btn-sm {
  width: 2rem;
  height: 2rem;
}
.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-primary {
  color: #fff;
  background-color: #FFCE00;
  border-color: #FFCE00;
  // box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: .82031rem;
}
.fw-medium {
  font-weight: 500;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.fs-6 {
  font-size: .9375rem;
}
.fs-80x {
  font-size: 80%;
}
.position-relative {
  position: relative
}
.position-absolute {
  position: absolute;
}
.border {
  border: 1px solid #dee2e6!important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d!important;
}
.rounded {
  border-radius: 0.375rem!important;
}
.modal-footer>* {
  margin: 0.25rem;
}
.mb-4 {
  margin-bottom: 1.5rem!important;
}
.form-control[readonly] {
  background-color: #f3f3f3;
  opacity: 1;
}
.ms-1 {
  margin-left: 0.25rem!important;
}
@media (min-width: 1200px){
.h4, h4 {
  font-size: 1.40625rem;
}
}
@media (max-width: 991px) {
  .chat-ui {
    border: none;
    // margin-top: 1rem;
    // margin-left: 0;
  }
  .header-top-info {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    padding: 10px 15px 20px;
    .header-top {
      border-radius: 5px;
    }
  }
  
  .chat-content-wrap {
    padding: 0;
  }
  .create-profile-wrap {
    margin-left: 0!important;
  }
  .user-search-wrapper {
    width: 100%;
    visibility: visible;
    &.hidden {
      display: none;
    }
  }
  .user-search-wrapper {
    width: 100%;
  }
  .offcanvas {
    visibility: visible;
  }
}
@media (max-width: 991.98px){
.profile-container {
  div.conversation-tips {
    visibility: hidden;
    &-show {
      margin-left: 0;
      visibility: visible;
      padding: 1.5rem
    }
  }
}

.chat-ui {
  .tab-content {
    display: none;
    &-show {
      display: block;
    }
  }
  .chat-content-share{
    .tab-content {
      display: block;
    }
    .room-name {
      font-size: 14px;
    }
    .chat-ui-bubble-reverse {
      margin-right: 1.5rem;
    }
  }
  .translate-tab {
    display: block;
    width: 100%;
    &.display {
      display: none;
    }
    .tab-pane {
      height: 100vh;
    }
  }
  &-client {
    .translate-header-content {
      justify-content: center;
      // margin-top: 60px;
      .icon-arrow-left-circle {
        position: absolute;
        left: 20px;
        color: #212529;
        font-size: 20px;
      }
    }
  }
}
.chat-ui-offcanvas {
  transform: translateX(0px);
  visibility: visible;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .tab-content {
    width: 100%;
    border-left: 1px solid #dee2e6;
  }
  
  .icon-arrow-left-circle {
    display: none;
  }
  .d-lg-none {
    display: none!important;
  }
}
@media (min-width: 768px) {
  .col-md-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
}
@media (min-width: 576px) {
  .col-sm-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
}
}