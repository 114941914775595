@import './theme.scss';
@import './tailwind.scss';
@import './font.scss';
html, body {
  background-color: transparent;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  margin: 0;
}
.button{
  &-link{
    font-size:12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover{
      opacity: .4;
    }
    i{
      font-size: 14px;
      margin-right: 5px;
    }
  }
  &-min{
    padding:6px 10px;
    cursor: pointer;
    background: #333;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    &:hover{
      color: #fff;
      background: #222;
    }
  }
}
.bonus-type-modal {
  .icon-close {
    display: none;
  }
}
.btn-operate-award {
  display: flex;
  margin-bottom: 16px;
  .btn {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .btn-primary {
    margin-right: 20px;
  }
}
.sign-in-wrapper {
  .btn-not-allowed {
    opacity: .4;
    cursor: not-allowed;
  }
  .content-client {
   margin-top: 0;
   padding: 0;
  }
  .token-wrapper {
    margin-top: 0;
  }
  .btn-operate-sign {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  .btn {
    width: 48%;
  }
 }
#scrollableDiv > div{
  width: 100%
 }
.numeric-keyboard-actionsheet {
  .numeric-keyboard-key[data-key=enter] {
    color: #fff;
    background: #FFCE00;
  }
}
.amount-wrapper {
  .numeric-input {
    background: #f6f6f6;
    width: inherit;
    display: flex;
    padding: 0;
    align-items: center;
  }
  .numeric-input-text {
    padding-right: 40px;
  }
}
.token-list-title {
  margin: 10px 0 2px;
  color: #FFCE00;
  font-weight: bold;
  font-size: 16px;
}
.token-list-name, .token-balance{
  font-size: 12px;
  opacity: .5;
}
.search-token {
  position: relative;
  .search-input {
    width: 100%;
    border-radius: 4px;
    margin: 12px 0;
    height: 40px;
  }
}
.bonus-type {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
}
.bonus-type-modal  {
  width: 400px;
  margin-top: 30px;
}
.message-tips{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: flex;
  padding: 6px 10px;
  position: absolute;
  width: 320px;
  left: -280px;
  bottom: -20px;
  &-success {
    background-color: rgba(128, 128, 128, 0.9);;
    color: #fff;
  }
  &-error {
    background-color: rgb(253, 237, 237);
    color: rgb(95, 33, 32);
  }
  &-success-mobile{
    width: 100px;
    bottom: 0px;
    left: 70%;
  }
}