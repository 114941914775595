.modal{
  background: #fff;
  padding:10px 20px 10px;
  border-radius: 4px;
  min-width: 430px;
  max-width: 1000px;
  position: relative;
  animation: sliderUp .5s ease;
  font-family: 'Helvetica';
  .connect-tips {
    margin-bottom: 20px;
  }
  .tip-text{
    font-size: 16px;
    margin: 10px 0 30px;
   }
  .approve-tips {
    margin: 30px 0
  }
  .approve-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  &-client{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: inherit;
  }
    &-wrap{
      z-index: 1001;
      position: fixed;
      background: transparent;
      left:0;
      top:0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  &-close{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    &:hover{
      opacity: .5;
    }
  }
  &-title{
    font-size: 16px;
    padding:20px 0 30px;
    margin:0;
  }
  .icon-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    box-sizing: content-box;
}
}
.button {
  background-color: #1868dc;
  border-radius: 23px;
  font-family: 'PingFang-SC-Heavy';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #f2f5f7;
  padding: 10px 23px;
  cursor: pointer;
  &.default {
    background-color: #e8e8e8;
    font-size: 16px;
    line-height: 4px;
    letter-spacing: 0px;
    color: #3e445b;
    height: 46px;
    width: 160px;
  }
  &.warning {
    background-color: #808080;
  }
  &.primary {
    background-color: #1868dc;
  }
}
.button-large {
  border-radius: 40px;
  font-size: 30px;
  padding: 19px 51px;
}
.button-small {
  border-radius: 40px;
  font-size: 14px;
  padding: 7px 16px;
}
@keyframes scale{
  0%{
    transform: scaleY(0);
    opacity: 0;
  }
  50%{
    opacity: 1;
    transform: scaleY(1.3);
  }
  to{
    transform: scaleY(0);
    opacity: 0;
  }
}
.mask{
  position: fixed;
  left:0;
  right: 0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: rgba($color: #000000, $alpha: .6);
  z-index: 100;
  transition: .4s;
}
@keyframes zoomScale {
   0%{
    transform: scale(0.9);
  }
  50%{
    transform: scale(1.1);
  }
  to{
    transform: scale(0.9);
  }
}
.page{
  &-load{
    position: fixed;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-loading{
    width: 60px;
    height: 60px;
    background-size: 100%;
    background-repeat: no-repeat;
    animation: zoomScale 1s ease-in infinite;
  }
}